import Message from "../../common/components/ui/Message";
import { userAnswersSelectors } from "../../common/store/slice/userAnswersSlice";
import { GoToQuestionnaireButton } from "../../features/questionnaire/GoToQuestionnaireButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const CompletedTestProtector = ({
  children,
}: React.PropsWithChildren) => {
  const hasAnswers = useSelector(userAnswersSelectors.hasAnswers);
  const { t } = useTranslation(["results"]);

  if (!hasAnswers) {
    return (
      <Message text={t("noResultsYet", { ns: "results" })}>
        <GoToQuestionnaireButton />
      </Message>
    );
  }
  return children;
};
