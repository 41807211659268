import NumberOne from "../../assets/icons/number_1.svg?react";
import NumberTwo from "../../assets/icons/number_2.svg?react";
import NumberThree from "../../assets/icons/number_3.svg?react";
import { Card } from "../../common/components/Card/Card";
import Heading from "../../common/components/ui/Heading";
import Paragraph from "../../common/components/ui/Paragraph";
import { useTranslation } from "react-i18next";

type ArrowPositions = {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
};
type MobileArrowImageProps = {
  side: "left" | "right";
  position: ArrowPositions;
};
const MobileImageArrow = ({ position, side }: MobileArrowImageProps) => (
  <img
    style={{
      ...position,
    }}
    src={`images/step-arrow-mobile-${side}.png`}
    alt="arrow pointing to next step"
    className="absolute lg:hidden"
  />
);
type ImageArrowProps = {
  side: "top" | "below";
  position: ArrowPositions;
};
const ImageArrow = ({ position, side }: ImageArrowProps) => (
  <img
    style={{
      ...position,
    }}
    src={`/images/step-arrow-${side}.png`}
    alt="arrow pointing to next step"
    className="absolute hidden lg:block"
  />
);

const steps = [
  { step: 1, Icon: NumberOne },
  { step: 2, Icon: NumberTwo },
  { step: 3, Icon: NumberThree },
];

export const InfoSteps = () => {
  const { t } = useTranslation(["frontpage"]);

  const renderArrows = (step: number): JSX.Element | null => {
    if (step === 1) {
      return (
        <>
          <ImageArrow
            side="below"
            position={{ right: "-70px", bottom: "-38px" }}
          />
          <MobileImageArrow
            side="right"
            position={{ right: "-16px", bottom: "-30px" }}
          />
        </>
      );
    }
    if (step === 2) {
      return (
        <>
          <ImageArrow side="top" position={{ right: "-70px", top: "-38px" }} />

          <MobileImageArrow
            side="left"
            position={{ left: "-16px", bottom: "-30px" }}
          />
        </>
      );
    }
    return null;
  };
  return (
    <div className="flex flex-col lg:flex-row gap-3 relative">
      {steps.map(({ step, Icon }) => (
        <Card key={step} className="rounded-lg p-5 gap-3 relative" noHoverStyle>
          {renderArrows(step)}
          <div className="flex justify-between mt-1">
            <Heading level={2} type="headline-sm-b" className="text-green-dark">
              {t(`infoSteps.step${step}.title`)}
            </Heading>
            <Icon className="-mt-[12px] -mr-[12px]" />
          </div>
          <Paragraph type="body-lg">
            {t(`infoSteps.step${step}.text`)}
          </Paragraph>
        </Card>
      ))}
    </div>
  );
};
