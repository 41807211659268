import { MediumCallToAction } from "../CallToActionButtons";
import { useTranslation } from "react-i18next";

export const FootprintCallToAction = () => {
  const { t } = useTranslation(["results"]);

  return (
    <div className="flex flex-col bg-green-100 px-6 pt-16 pb-10 sm:px-10 sm:py-14 rounded-2xl sm:rounded-lg gap-6 sm:gap-4 items-center sm:items-start bg-windmill-small before:bg-green-120 before:opacity-50">
      <p className="headline-md-b text-basic-white flex-1">
        {t("greenCTA.description", { ns: "results" })}
      </p>
      <div>
        <MediumCallToAction theme="planCTA" trackLocation="footprint-card">
          {t("greenCTA.button", { ns: "results" })}
        </MediumCallToAction>
      </div>
    </div>
  );
};
