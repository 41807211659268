import { locationSelectors } from "../../store/slice/locationSlice";
import Heading from "../ui/Heading";
import Tag from "../ui/Tag";
import { TextLinkButton } from "../ui/buttons/TextLinkButton";
import IdeaActionCardCorner from "./ActionCardCorner/IdeaActionCardCorner";
import ImpactActionCardCorner from "./ActionCardCorner/ImpactActionCardCorner";
import { ActionHelpModal } from "./ActionHelpModal";
import {
  getActionDescriptionTranslationKey,
  getActionTitleTranslationKey,
} from "./utils";
import { RegionalNamespaces } from "@pslifestyle/common/src/constants/translations";
import {
  CalculatedAction,
  TagTheme,
} from "@pslifestyle/common/src/types/planTypes";
import { ReactNode, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface Props {
  action: CalculatedAction;
  children: ReactNode;
  cyData?: string;
  showHelpLinks?: boolean;
}

const ActionCard = ({
  action,
  children,
  cyData,
  showHelpLinks,
}: Props): JSX.Element | null => {
  const { t } = useTranslation("recommendations");
  const country = useSelector(locationSelectors.country);

  const [showMore, setShowMore] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const { category, calculatedImpact, percentReduction } = action;

  if (!country) return null;

  return (
    <>
      <div className="bg-basic-white rounded-2xl" data-cy={cyData}>
        <div className="flex flex-row">
          <div className="grow pt-6 pl-5 pb-1">
            <Tag
              filter={action.category.toLowerCase()}
              key={action.category}
              variant={action.category.toLowerCase() as TagTheme}
            >
              {t(`categories.${action.category.toLowerCase()}`, {
                ns: "common",
              })}
            </Tag>
            <Heading level={3} type="title-md" className="mt-3">
              {t(getActionTitleTranslationKey(action.id, country.code), {
                ns: RegionalNamespaces.QUESTION_AND_RECOMMENDATIONS,
              })}
            </Heading>
            <TextLinkButton
              onClick={() => setShowMore(!showMore)}
              icon={{
                position: "right",
                size: "medium",
                type: showMore ? "ChevronUp" : "ChevronDown",
              }}
              buttonClassName="mt-2.5"
            >
              {showMore ? t("hide", { ns: "common" }) : t("seeMore")}
            </TextLinkButton>
          </div>
          <div>
            {Math.round(action.calculatedImpact) > 0 ? (
              <ImpactActionCardCorner
                category={category}
                calculatedImpact={calculatedImpact}
                percentReduction={percentReduction}
              />
            ) : (
              <IdeaActionCardCorner category={category} />
            )}
          </div>
        </div>
        <div
          className={`ml-3 overflow-hidden transition-max-height ease-in-out duration-300 ${
            showMore ? "max-h-screen" : "max-h-0"
          }`}
        >
          <div className="flex flex-col gap-4 px-2 mb-2 body-md">
            {t(getActionDescriptionTranslationKey(action.id, country.code), {
              ns: RegionalNamespaces.QUESTION_AND_RECOMMENDATIONS,
            })
              .split("\n")
              .filter((str) => str.length > 0)
              .map((str, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={`str${index}`}>{str}</p>
              ))}
            {action.help && (
              <TextLinkButton
                className="text-cyan-dark items-start"
                buttonClassName="flex"
                onClick={() => setShowHelp(true)}
              >
                {t("needHelpWithThisAction")}
              </TextLinkButton>
            )}
          </div>
        </div>
        <div className="flex flex-col mx-auto w-full mt-2.5">{children}</div>
      </div>
      <ActionHelpModal
        open={showHelp}
        onClose={() => setShowHelp(false)}
        action={action}
        showHelpLinks={showHelpLinks}
        country={country}
      />
    </>
  );
};

export default ActionCard;
