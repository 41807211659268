import { useAppDispatch } from "../../app/store";
import { ContainerLoader } from "../../common/components/loaders/ContainerLoader";
import { resetEntityStores } from "../../common/store/actions";
import {
  authedSessionActions,
  authedSessionSelectors,
} from "../../common/store/slice/authedSessionSlice";
import { isErrorWithMessage } from "../../common/utils/helpers";
import { checkLink } from "../../firebase/api/checkLink";
import LoginFromOtherDeviceErrorModal from "./LoginFromOtherDeviceErrorModal";
import LoginFromOtherDeviceView from "./LoginFromOtherDeviceView";
import { User } from "@pslifestyle/common/src/models/user";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export const CheckLoginPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const magicLinkTokenFromLink = searchParams.get("");

  const user = useSelector(authedSessionSelectors.user);
  const magicLinkEmail = useSelector(authedSessionSelectors.magicLinkEmail);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const checkEmailAndToken = useCallback(
    async (magicLinkEmail: string, magicLinkTokenFromLink: string) => {
      setLoading(true);
      try {
        const {
          data: { sessionToken, redirectDestination },
        } = await checkLink(magicLinkEmail, magicLinkTokenFromLink);

        if (sessionToken) {
          dispatch(resetEntityStores());
          const jwtData: { email: string; roles: User["roles"] } =
            jwtDecode(sessionToken);

          dispatch(
            authedSessionActions.setUserLoggedIn({
              email: jwtData.email,
              sessionToken,
              roles: jwtData.roles || [],
            }),
          );
          navigate(`/${redirectDestination}`);
        }
      } catch (err: unknown) {
        console.log(err);
        if (isErrorWithMessage(err)) {
          console.log("loginWithMagicLinkError", err.message);
          setErrorOccurred(true);
        }
        setLoading(false);
      }
    },
    [navigate, dispatch],
  );

  useEffect(() => {
    if (!magicLinkTokenFromLink) {
      navigate("/login");
      return;
    }
    if (user) {
      return;
    }
    if (magicLinkEmail) {
      checkEmailAndToken(magicLinkEmail, magicLinkTokenFromLink);
    }
  }, [
    magicLinkEmail,
    checkEmailAndToken,
    magicLinkTokenFromLink,
    user,
    navigate,
  ]);

  return (
    <ContainerLoader loading={loading}>
      <LoginFromOtherDeviceErrorModal
        onClose={() => {
          dispatch(authedSessionActions.setMagicLinkEmail(null));
          navigate("/login");
        }}
        open={errorOccurred}
      />

      <div className="container mx-auto p-2 flex flex-col max-w-[800px]">
        <div className="flex justify-center">
          <LoginFromOtherDeviceView
            onSubmitEmail={(email: string) =>
              dispatch(authedSessionActions.setMagicLinkEmail(email))
            }
          />
        </div>
      </div>
    </ContainerLoader>
  );
};
