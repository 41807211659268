import Heading from "../ui/Heading";
import Paragraph from "../ui/Paragraph";
import { ExternalLinkButton } from "../ui/buttons/ExternalLinkButton";
import NotificationDialog from "../ui/dialogs/NotificationDialog";
import {
  getActionHelpTranslationKey,
  getActionLinkTranslationKey,
  getActionTitleTranslationKey,
} from "./utils";
import { RegionalNamespaces } from "@pslifestyle/common/src/constants/translations";
import { Country } from "@pslifestyle/common/src/models/countries";
import { CalculatedAction } from "@pslifestyle/common/src/types/planTypes";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  onClose: () => void;
  showHelpLinks?: boolean;
  action: CalculatedAction;
  country: Country;
};
export const ActionHelpModal = ({
  open,
  onClose,
  showHelpLinks,
  action,
  country,
}: Props) => {
  const { t } = useTranslation([
    RegionalNamespaces.QUESTION_AND_RECOMMENDATIONS,
    "common",
    "recommendations",
  ]);

  return (
    <NotificationDialog
      open={open}
      onClose={onClose}
      closeButtonText={t("close", { ns: "common" })}
    >
      <Heading level={2} type="headline-xs-eb">
        {t(getActionTitleTranslationKey(action.id, country.code))}
      </Heading>
      {t(getActionHelpTranslationKey(action.id, country.code))
        .split("\n")
        .filter((str) => str)
        .map((str, index) => (
          <Paragraph
            type="body-lg"
            className="py-1 w-full text-left"
            // eslint-disable-next-line react/no-array-index-key
            key={`str${index}`}
          >
            {str}
          </Paragraph>
        ))}

      {!showHelpLinks && (
        <>
          <div className=" w-full h-40 -mt-20 bg-gradient-to-t from-basic-white to-basic-white/75" />
          <div className=" w-full h-full pt-2">
            <Paragraph type="body-lg" className="">
              {t("chooseThisForMoreInfo", { ns: "recommendations" })}:
            </Paragraph>
          </div>
        </>
      )}
      {showHelpLinks && action.links && action.links.length > 0 && (
        <>
          <Paragraph type="body-lg-b" className="w-full text-left mb-3">
            {t("helpfulLinks", { ns: "recommendations" })}:
          </Paragraph>
          <div className="flex flex-col w-full gap-2">
            {action.links.map((link) => (
              <ExternalLinkButton
                theme="secondary"
                key={link.link}
                url={link.link}
                text={`${t(getActionLinkTranslationKey(link.linkTranslationKey, country.code))}`}
              />
            ))}
          </div>
        </>
      )}
    </NotificationDialog>
  );
};
