import { ButtonLarge } from "../../common/components/ui/buttons";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

const trackId = "gtm-results-cta-button";

type Props = {
  trackLocation: "profile-card" | "footer" | "category-card" | "footprint-card";
  theme?: "blackCTA" | "planCTA";
};

const navigateTo = "/recommendations/intro";
export const LargeCallToAction = ({
  children,
  theme,
  trackLocation,
}: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  return (
    <ButtonLarge
      id={`${trackId}-${trackLocation}`}
      theme={theme}
      onClick={() => navigate(navigateTo)}
      icon={{ position: "right", type: "ChevronRight" }}
    >
      {children}
    </ButtonLarge>
  );
};

export const MediumCallToAction = ({
  children,
  theme,
  trackLocation,
}: PropsWithChildren<Props>) => {
  const navigate = useNavigate();

  return (
    <ButtonLarge
      id={`${trackId}-${trackLocation}`}
      theme={theme}
      onClick={() => navigate(navigateTo)}
      icon={{ position: "right", type: "ChevronRight" }}
    >
      {children}
    </ButtonLarge>
  );
};
