import { FullWidthContainer } from "../../layout/Container";
import { BackButton } from "./index";
import * as React from "react";
import { HTMLAttributes } from "react";

type BackButtonInContainerProps = HTMLAttributes<HTMLButtonElement> & {
  containerClassName?: string;
};

const BackButtonInContainer = ({
  containerClassName,
  ...restProps
}: BackButtonInContainerProps) => (
  <FullWidthContainer
    className={`items-start mt-4 mb-10 ${containerClassName}`}
  >
    <BackButton {...restProps} />
  </FullWidthContainer>
);

export default BackButtonInContainer;
