import { FullWidthContainer } from "../../common/components/layout/Container";
import Heading from "../../common/components/ui/Heading";
import Paragraph from "../../common/components/ui/Paragraph";
import { LoginForm } from "./LoginForm";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface IProps {
  onSubmitEmail: (email: string) => void;
}

const LoginFormView = ({ onSubmitEmail }: IProps) => {
  const { t } = useTranslation();

  return (
    <FullWidthContainer className="py-8" data-cy="loginForm">
      <Heading level={1} type="headline-md-b">
        {t("enterEmail", { ns: "authentication" })}
      </Heading>
      <LoginForm onSubmitEmail={onSubmitEmail} />

      <Paragraph type="body-lg">
        <Trans
          i18nKey="authentication:signInAcceptance"
          components={{
            Link: <Link className="underline" to="/privacynotice" />,
          }}
        />
      </Paragraph>
    </FullWidthContainer>
  );
};

export default LoginFormView;
