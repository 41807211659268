import { userAnswersSelectors } from "../store/slice/userAnswersSlice";
import { userPlanSelectors } from "../store/slice/userPlanSlice";
import { round } from "@pslifestyle/common/src/helpers/securedMathjs";
import { useSelector } from "react-redux";

export const usePercentageFromPickedActions = () => {
  const totalFootprint = useSelector(userAnswersSelectors.totalFootprint);
  const totalImpact = useSelector(userPlanSelectors.totalImpact);

  return round((totalImpact / totalFootprint) * 100, 1);
};
