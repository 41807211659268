import { FullWidthContainer } from "../../common/components/layout/Container";
import Heading from "../../common/components/ui/Heading";
import Paragraph from "../../common/components/ui/Paragraph";
import { GoToQuestionnaireButton } from "../questionnaire/GoToQuestionnaireButton";
import { InfoSteps } from "./InfoSteps";
import { useTranslation } from "react-i18next";

export const Hero = () => {
  const { t } = useTranslation(["frontpage"]);
  return (
    <div className="pb-8">
      <FullWidthContainer className="gap-3 relative">
        <img
          src="/images/homepage_bg_1.jpg"
          alt=""
          className="w-[258px] self-end -mr-4 lg:w-[500px] lg:absolute lg:right-0 lg:top-0"
        />
        <div className="flex flex-col justify-around gap-4 mb-6 lg:mb-0 lg:w-[500px] lg:pt-[300px] lg:pb-[80px]">
          <Heading level={1} type="headline-xl-eb" className="text-neutral-100">
            The Lifestyle Test
          </Heading>
          <Paragraph type="body-lg">{t("heroHook")}</Paragraph>
          <GoToQuestionnaireButton
            trackLocation="home-hero"
            buttonClassName="lg:self-start mt-1"
          />
        </div>
        <InfoSteps />
      </FullWidthContainer>
    </div>
  );
};
