import { store } from "../app/store";
import { locationActions } from "../common/store/slice/locationSlice";
import { setI18nLanguage } from "./utils";
import { defaultLanguageCode } from "@pslifestyle/common/src/models/languages";

export default {
  name: "customLocalStorage",

  lookup: function lookup(): string | undefined {
    const { country, language } = store.getState().location;
    if (country) {
      store.dispatch(locationActions.setCountryByCountryCode(country.code));
    }
    if (!language) {
      setI18nLanguage(`${defaultLanguageCode}:EU`);
    }
    return language ?? undefined;
  },

  cacheUserLanguage: () => {
    // do nothing as redux is persisted
    // default english is only set to render text until user makes their selection
  },
};
