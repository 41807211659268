/**
 * Component for showing the category of the current question.
 */
import Tag from "../../common/components/ui/Tag";
import { RegionalNamespaces } from "@pslifestyle/common/src/constants/translations";
import getCategoryFromSortKey from "@pslifestyle/common/src/helpers/getCategoryFromSortKey";
import { TagTheme } from "@pslifestyle/common/src/types/planTypes";
import { useTranslation } from "react-i18next";

export interface Props {
  sortKey: string;
}

const QuestionnaireCategoryTag: React.FC<Props> = ({ sortKey }) => {
  const { t } = useTranslation([
    "common",
    RegionalNamespaces.QUESTION_AND_RECOMMENDATIONS,
  ]);

  const category = getCategoryFromSortKey(sortKey);

  if (!category) {
    return null;
  }

  return (
    <Tag
      filter={category.toLowerCase()}
      key={category}
      variant={category.toLowerCase() as TagTheme}
      className="self-center my-2"
    >
      {t(`categories.${category.toLowerCase()}`, {
        ns: "common",
      })}
    </Tag>
  );
};

export default QuestionnaireCategoryTag;
