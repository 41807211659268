import { FullWidthContainer } from "../../common/components/layout/Container";
import { LoginForm } from "./LoginForm";
import { useTranslation } from "react-i18next";

interface Props {
  onSubmitEmail: (email: string) => unknown;
}

const LoginFromOtherDeviceView = ({ onSubmitEmail }: Props) => {
  const { t } = useTranslation();

  return (
    <FullWidthContainer className="py-4" data-cy="loginFromOtherDevice">
      <p>{t("differentDevice", { ns: "authentication" })}</p>
      <LoginForm onSubmitEmail={onSubmitEmail} />
    </FullWidthContainer>
  );
};

export default LoginFromOtherDeviceView;
