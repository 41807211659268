import { setI18nLanguage } from "../../../i18n/utils";
import { locationActions } from "../slice/locationSlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";

const locationMiddleware = createListenerMiddleware();

locationMiddleware.startListening({
  actionCreator: locationActions.setLanguage,
  effect: (action) => {
    setI18nLanguage(action.payload);
  },
});

export { locationMiddleware };
