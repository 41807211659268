import React from "react";

type Props = {
  className?: string;
  noHoverStyle?: boolean;
};
export const Card = ({
  children,
  className,
  noHoverStyle,
}: React.PropsWithChildren<Props>) => (
  <div
    className={`
  w-full
  flex
  flex-col
  gap-0
  rounded-2xl
  bg-neutral-white
  break-words
  transition-all
  duration-200
  shadow-neutral-20
  shadow
  ${noHoverStyle ? "" : "hover:shadow-lg"}
  ${className ?? ""}`}
  >
    {children}
  </div>
);
