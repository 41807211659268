export type Country = {
  name: string;
  code: string;
  languages: string[];
  defaultLanguage: string;
};

export const countries: Country[] = [
  {
    name: "Europe",
    code: "EU",
    languages: ["en-GB"],
    defaultLanguage: "en-GB",
  },
  {
    name: "Estonia",
    code: "EE",
    languages: ["et-EE", "ru-RU", "en-GB"],
    defaultLanguage: "et-EE",
  },
  {
    name: "Finland",
    code: "FI",
    languages: ["fi-FI", "en-GB", "sv-FI"],
    defaultLanguage: "fi-FI",
  },
  {
    name: "Germany",
    code: "DE",
    languages: ["de-DE", "en-GB"],
    defaultLanguage: "de-DE",
  },
  {
    name: "Greece",
    code: "GR",
    languages: ["el-GR", "en-GB"],
    defaultLanguage: "el-GR",
  },
  {
    name: "Italy",
    code: "IT",
    languages: ["it-IT", "en-GB"],
    defaultLanguage: "it-IT",
  },
  {
    name: "Norway",
    code: "NO",
    languages: ["nb-NO", "en-GB"], // Bokmål
    defaultLanguage: "nb-NO",
  },
  {
    name: "Poland",
    code: "PL",
    languages: ["pl-PL", "en-GB"],
    defaultLanguage: "pl-PL",
  },
  {
    name: "Portugal",
    code: "PT",
    languages: ["pt-PT", "en-GB"],
    defaultLanguage: "pt-PT",
  },
  {
    name: "Slovenia",
    code: "SI",
    languages: ["sl-SI", "en-GB"],
    defaultLanguage: "sl-SI",
  },
  {
    name: "Turkey",
    code: "TR",
    languages: ["tr-TR", "en-GB"],
    defaultLanguage: "tr-TR",
  },
];

export const countryCodes = countries.map((country) => country.code);
export const countryLanguages = countries.reduce(
  (acc, country) =>
    acc.concat(country.languages.map((lang) => `${lang}:${country.code}`)),
  [] as string[],
);
export const getMissingSupportedCountryCodes = (
  providedCodes: string[],
): string[] => countryCodes.filter((code) => !providedCodes.includes(code));

export function getCountryObjectByCode(code: string) {
  return countries.find((country) => country.code === code);
}
