import { useAppDispatch } from "../../../app/store";
import useTimerBasedState from "../../hooks/useTimerBasedState";
import { locationSelectors } from "../../store/slice/locationSlice";
import { userPlanActions } from "../../store/slice/userPlanSlice";
import FeedbackModalFlow from "../Feedback/FeedbackModalFlow";
import { ButtonMedium } from "../ui/buttons";
import ActionCard from "./ActionCard";
import MainActionCardButton from "./ActionCardButtons/MainActionCardButton";
import SkipActionModalButton from "./ActionCardButtons/SkipActionModalButton";
import ActionCardExtraActivityContainer from "./ActionCardExtraActivityContainer";
import { ActionHelpModal } from "./ActionHelpModal";
import { ReactionAnimation } from "./ReactionAnimation";
import { CalculatedAction } from "@pslifestyle/common/src/types/planTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface Props {
  action: CalculatedAction;
}

const DefaultPlanActionCard = ({
  action,
  onActionCompleteClick,
}: Props & {
  onActionCompleteClick: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation("recommendations");
  const dispatch = useAppDispatch();
  const [showHelp, setShowHelp] = useState(false);
  const country = useSelector(locationSelectors.country);
  if (!country) return null;

  return (
    <>
      <div className="rounded-2xl  border-4 border-green-100">
        <ActionCard
          action={action}
          cyData={`planActionCard.default.card.${action.id}`}
          showHelpLinks
        >
          <ActionCardExtraActivityContainer displaySingleActivity={false}>
            <SkipActionModalButton action={action} />
            {action.help ? (
              <ButtonMedium
                theme="secondary"
                buttonClassName="w-full"
                className="w-full justify-center !leading-4"
                onClick={() => setShowHelp(true)}
              >
                {t("needHelp")}
              </ButtonMedium>
            ) : (
              <div className="w-full">
                <FeedbackModalFlow>
                  <ButtonMedium
                    theme="secondary"
                    buttonClassName="w-full"
                    className="w-full justify-center !leading-4"
                  >
                    {t("needHelp")}
                  </ButtonMedium>
                </FeedbackModalFlow>
              </div>
            )}
          </ActionCardExtraActivityContainer>
          <MainActionCardButton
            theme="goalCTA"
            onClick={() => {
              dispatch(
                userPlanActions.changeActionState({
                  actionId: action.id,
                  newState: "completed",
                }),
              );
              onActionCompleteClick();
            }}
          >
            {t("markActionAsDone")}
          </MainActionCardButton>
        </ActionCard>
      </div>
      <ActionHelpModal
        country={country}
        open={showHelp}
        onClose={() => setShowHelp(false)}
        action={action}
        showHelpLinks
      />
    </>
  );
};

const CompletedPlanActionCard = ({
  action,
  onActionUncompleteClick,
  showAnimation,
}: Props & {
  onActionUncompleteClick: () => void;
  showAnimation: boolean;
}): JSX.Element => {
  const { t } = useTranslation("recommendations");
  const dispatch = useAppDispatch();

  return (
    <div className="rounded-2xl  border-4 border-green-140">
      <ActionCard
        action={action}
        cyData={`planActionCard.completed.card.${action.id}`}
        showHelpLinks
      >
        <ReactionAnimation show={showAnimation} reactionType="heartColor" />
        <MainActionCardButton
          theme="goalCTAActive"
          onClick={() => {
            dispatch(
              userPlanActions.changeActionState({
                actionId: action.id,
                newState: "new",
              }),
            );
            onActionUncompleteClick();
          }}
          icon={{ position: "left", type: "Check" }}
        >
          <span className="ml-2.5">{t("actionCompleted")}</span>
        </MainActionCardButton>
      </ActionCard>
    </div>
  );
};

const PlanActionCard = ({ action }: Props): JSX.Element => {
  const isActionCompleted = action.state === "completed";
  const {
    state: actionCompletedAnimationState,
    activateState: activateActionCompletedAnimation,
    deactivateState: deactivateActionCompletedAnimation,
  } = useTimerBasedState(5900);

  if (isActionCompleted) {
    return (
      <CompletedPlanActionCard
        action={action}
        onActionUncompleteClick={() => deactivateActionCompletedAnimation()}
        showAnimation={actionCompletedAnimationState}
      />
    );
  }

  return (
    <DefaultPlanActionCard
      action={action}
      onActionCompleteClick={() => activateActionCompletedAnimation()}
    />
  );
};

export default PlanActionCard;
