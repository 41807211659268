import { Button, IButtonProps } from "./Button";
import {
  ButtonInnerContainer,
  IButtonInnerContainerProps,
} from "./ButtonInnerContainer";
import { Theme, themeClassNamesMap } from "./theme";
import { ForwardedRef, forwardRef } from "react";

export interface IThemedButtonProps
  extends IButtonProps,
    IButtonInnerContainerProps {
  theme?: Theme;
  buttonClassName?: string;
}

export const ThemedButton = forwardRef(
  (
    {
      theme = "primary",
      children,
      icon,
      className,
      buttonClassName,
      ...restProps
    }: IThemedButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <Button {...restProps} className={buttonClassName} ref={ref}>
      <ButtonInnerContainer
        icon={icon}
        className={`${themeClassNamesMap[theme]} ${className || ""}`}
      >
        {children}
      </ButtonInnerContainer>
    </Button>
  ),
);
