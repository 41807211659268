import { FullWidthContainer } from "../../common/components/layout/Container";
import Heading from "../../common/components/ui/Heading";
import Paragraph from "../../common/components/ui/Paragraph";
import { ButtonLarge } from "../../common/components/ui/buttons";
import BackButtonInContainer from "../../common/components/ui/buttons/BackButtonInContainer";
import useTrack from "../../common/hooks/useTrack";
import { colors } from "../../common/utils/helpers";
import IntroToDemographic from "./IntroToDemographic";
import { useTranslation } from "react-i18next";

const mapCategoryToImagePath = (category: string) => {
  switch (category) {
    case "housing":
      return "/images/icons/sofa.png";
    case "transport":
      return "/images/icons/backpack.png";
    case "food":
      return "/images/icons/food.png";
    case "purchases":
      return "/images/icons/bag.png";
    default:
      console.error("Unknown category: ", category);
      return "";
  }
};

interface IntroToQuestionnaireCategoryProps {
  category: string;
  onBackClick: () => void;
  onPrimaryButtonClick: () => void;
  onFinishQuestionnaireClick: () => void;
  visibleQuestionIndex: number;
}

const IntroToQuestionnaireCategory: React.FC<
  IntroToQuestionnaireCategoryProps
> = ({
  category,
  onBackClick,
  onPrimaryButtonClick,
  onFinishQuestionnaireClick,
  visibleQuestionIndex,
}) => {
  const { t } = useTranslation(["intros"]);

  const isOnFirstQuestion = visibleQuestionIndex === 0;

  useTrack(category);

  if (category === "demographic") {
    return (
      <IntroToDemographic
        onBackClick={onBackClick}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onFinishQuestionnaireClick={onFinishQuestionnaireClick}
      />
    );
  }

  const categoryImagePath = mapCategoryToImagePath(category);

  return (
    <>
      <BackButtonInContainer
        onClick={!isOnFirstQuestion ? onBackClick : undefined}
        containerClassName="mb-2"
      />
      <FullWidthContainer className="grow">
        <img
          className="grow-[3] basis-0 min-w-0 min-h-0 max-h-[400px] object-contain mx-auto shorter:hidden"
          alt=""
          src={categoryImagePath}
        />
        <div className="flex flex-col grow gap-4 pb-4 px-4 text-center">
          <Heading
            level={1}
            type="headline-lg-eb"
            className={`text-${colors[category]}-110`}
          >
            {t(`introToQuestionnaireCategory.${category}.title`)}
          </Heading>
          <Paragraph type="body-lg">
            {t(`introToQuestionnaireCategory.${category}.paragraph2`)}
          </Paragraph>
          <ButtonLarge
            onClick={onPrimaryButtonClick}
            cyData="enterNextCategory.button"
          >
            {t("continue", { ns: "common" })}
          </ButtonLarge>
        </div>
      </FullWidthContainer>
    </>
  );
};

export default IntroToQuestionnaireCategory;
