import { Icon, IconProps } from "../Icon";
import { ForwardedRef, forwardRef, ReactNode } from "react";

export interface IButtonInnerContainerProps {
  icon?: IconProps & {
    position: "left" | "right";
  };
  children?: ReactNode;
  className?: string;
}

const defaultClassNames = `
      items-center
      inline-flex
      cusror-pointer
      no-underline 
      hover:no-underline 
      group-focus:no-underline`;

export const ButtonInnerContainer = forwardRef(
  (
    { icon, className, children }: IButtonInnerContainerProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    let flexStyleForIcon = "";
    if (icon) {
      flexStyleForIcon =
        icon.position === "left" ? "flex-row" : "flex-row-reverse";
    }
    return (
      <span
        className={`${defaultClassNames} ${
          flexStyleForIcon
        } ${className || ""}`}
        ref={ref}
      >
        {children}
        {icon && <Icon type={icon.type} size={icon.size} />}
      </span>
    );
  },
);
