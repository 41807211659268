import BlackChevronRightTriple from "../../assets/icons/chevron-right-triple_black.svg?react";
import YellowChevronRightTriple from "../../assets/icons/chevron-right-triple_yellow.svg?react";
import ButtonLarge, {
  iconRightClassNames,
} from "../../common/components/ui/buttons/ButtonLarge";
import { IThemedButtonProps } from "../../common/components/ui/buttons/ThemedButton";
import RetakeQuestionnaireAlertDialog from "./RetakeQuestionnaireAlertDialog";
import { useTranslation } from "react-i18next";

interface GoToQuestionnaireButtonProps extends IThemedButtonProps {
  children?: string;
  trackLocation?: "home-hero" | "home-article";
  theme?: "primary" | "blackCTA";
}

export const GoToQuestionnaireButton = ({
  children,
  trackLocation,
  ...props
}: GoToQuestionnaireButtonProps) => {
  const { t } = useTranslation(["common"]);
  const theme = props.theme ?? "primary";
  return (
    <RetakeQuestionnaireAlertDialog>
      <ButtonLarge
        id={`gtm-take-test${trackLocation ? `-${trackLocation}` : ""}`}
        theme={theme}
        className={`justify-between ${iconRightClassNames}`}
        cyData="take-test-button"
        {...props}
      >
        {children || t("startTheTest", { ns: "frontpage" })}
        {theme === "primary" ? (
          <BlackChevronRightTriple />
        ) : (
          <YellowChevronRightTriple />
        )}
      </ButtonLarge>
    </RetakeQuestionnaireAlertDialog>
  );
};
