import { ButtonLarge } from "../../common/components/ui/buttons";
import { VerticalButtonsContainer } from "../../common/components/ui/buttons/VerticalButtonsContainer";
import { simpleEmailRegex } from "./constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onSubmitEmail: (email: string) => unknown;
}

export const LoginForm = ({ onSubmitEmail }: Props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const handleEmailSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmitEmail(email);
    setEmail("");
  };
  return (
    <form onSubmit={handleEmailSubmit} className="flex flex-col justify-center">
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className="p-4 my-8 border border-neutral-10 rounded shadow focus:outline-none w-full"
        type="email"
        id="email"
        name="email"
        placeholder="Email@address.com"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <VerticalButtonsContainer>
        <ButtonLarge
          type="submit"
          disabled={!simpleEmailRegex.test(email.toLowerCase())}
          className="justify-center"
        >
          {t("sendLink", { ns: "authentication" })}
        </ButtonLarge>
      </VerticalButtonsContainer>
    </form>
  );
};
