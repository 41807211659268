export const getActionLinkTranslationKey = (key: string, countryCode: string) =>
  `${key}_${countryCode}`;

const createTranslationKey =
  (type: string) => (key: string, countryCode: string) =>
    `${key}_${type}_${countryCode}`;

export const getActionTitleTranslationKey = createTranslationKey("actionTitle");
export const getActionHelpTranslationKey = createTranslationKey("actionHelp");
export const getActionDescriptionTranslationKey =
  createTranslationKey("actionDescription");
