import { getStorage } from "../firebase/FirestoreClient";
import { RegionalNamespaces } from "@pslifestyle/common/src/constants/translations";
import {
  defaultLanguageCode,
  languagesConfig,
} from "@pslifestyle/common/src/models/languages";
import { getDownloadURL, ref } from "firebase/storage";
import i18next from "i18next";

export const commonNamespaces = [
  "accessibility",
  "authentication",
  "common",
  "feedback",
  "frontpage",
  "intros",
  "management",
  "questionnaire",
  "recommendations",
  "results",
  "staticTranslations",
];

export const regionalNamespaces = [
  RegionalNamespaces.QUESTION_AND_RECOMMENDATIONS,
  RegionalNamespaces.FEEDBACK_CARDS,
];

const storage = getStorage();

async function getRegionTranslationsUrl(
  countryCode: string,
  language: string,
  ns: string,
) {
  const path = `/i18n/${countryCode}/${language}/${ns}.json`;
  const starsRef = ref(await storage, path);
  const url = await getDownloadURL(starsRef);
  return url;
}

async function getCommonTranslationsUrl(language: string, ns: string) {
  const path = `/i18n/${language}/${ns}.json`;
  const starsRef = ref(await storage, path);
  const url = await getDownloadURL(starsRef);
  return url;
}

export function formatLanguageCode(languageCode: string, countryCode: string) {
  return `${languageCode}:${countryCode}`;
}

export function parseLanguageCode(language: string | null) {
  if (!language) {
    throw new Error("Language code is null");
  }
  const [languageCode, countryCode] = language.split(":");
  return { languageCode, countryCode };
}

export const languageToNativeLanguageName = (
  language: string | null,
): string => {
  const defaultLanguage = "English";
  if (!language) {
    return defaultLanguage;
  }
  const { languageCode } = parseLanguageCode(language);
  return languagesConfig[languageCode]
    ? languagesConfig[languageCode].nativeName
    : defaultLanguage;
};

export function extractLanguageCodeOrDefault(language: string | null) {
  try {
    return parseLanguageCode(language).languageCode || defaultLanguageCode;
  } catch (error) {
    return defaultLanguageCode;
  }
}

export async function getTranslationUrl(countryLanguage: string, ns: string) {
  const { languageCode, countryCode } = parseLanguageCode(countryLanguage);
  if (regionalNamespaces.includes(ns as RegionalNamespaces)) {
    return getRegionTranslationsUrl(countryCode, languageCode, ns);
  }
  if (commonNamespaces.includes(ns)) {
    return getCommonTranslationsUrl(languageCode, ns);
  }
  throw new Error(`Unknown namespace ${ns}`);
}

export const setI18nLanguage = (language: string) =>
  i18next.changeLanguage(language);
