import { USE_EMULATOR } from "../app/config";
import { IS_PROD, IS_DEV } from "../common/utils/currentEnv";
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfigDev = {
  apiKey: "AIzaSyAPUBjYV6hIn2DxDE-uz9RGpBvEdnG4nZ8",
  authDomain: "hanki-2631-pslifestyle-dev.firebaseapp.com",
  projectId: "hanki-2631-pslifestyle-dev",
  storageBucket: "hanki-2631-pslifestyle-dev.appspot.com",
  messagingSenderId: "677556975772",
  appId: "1:677556975772:web:3380e62b31c1e49f095559",
  measurementId: "G-7R4QBSPPBJ",
};

const firebaseConfigProd = {
  projectId: "hanki-2631-pslifestyle-prod",
  appId: "1:668693361893:web:c9a6551c254f069ae8900c",
  storageBucket: "hanki-2631-pslifestyle-prod.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyAP_ErGkkvhApdGRXTJbWAN4dhUoqFuvu0",
  authDomain: "hanki-2631-pslifestyle-prod.firebaseapp.com",
};

const firebaseConfigDev2 = {
  apiKey: "AIzaSyCiy3rYZ4SU9WYYfbVPLw3nfNDnwQGHVqY",
  authDomain: "hanki-2631-pslifestyle-dev2.firebaseapp.com",
  projectId: "hanki-2631-pslifestyle-dev2",
  storageBucket: "hanki-2631-pslifestyle-dev2.appspot.com",
  messagingSenderId: "464605167934",
  appId: "1:464605167934:web:f502c6a0f303e4fae1ac6f",
};

const getFirebaseConfig = () => {
  if (IS_PROD) {
    return firebaseConfigProd;
  }
  if (IS_DEV) {
    return firebaseConfigDev;
  }
  return firebaseConfigDev2;
};

// Initialize Firebase asynchronously and return the Firestore instance
export async function initializeFirebase() {
  const firebaseInstance = initializeApp(getFirebaseConfig());
  const firestore = getFirestore(firebaseInstance);
  if (USE_EMULATOR) {
    connectFirestoreEmulator(firestore, "localhost", 8080);
  }

  return firestore;
}

// Store the initialized Firestore instance
export const firestoreInstancePromise = initializeFirebase();
