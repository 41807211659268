import { RootState } from "../../../app/store";
import { formatLanguageCode } from "../../../i18n/utils";
import { AppDispatch } from "../../utils/testHelpers";
import { resetStateForTesting } from "../actions";
import { createDeepEqualSelector } from "../utils";
import {
  Country,
  getCountryObjectByCode,
} from "@pslifestyle/common/src/models/countries";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface LocationState {
  country: Country | null;
  language: string | null;
  allowedCountries: string[];
}

const initialState: LocationState = {
  country: null,
  language: null,
  allowedCountries: [],
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setCountry(state, action: PayloadAction<Country>) {
      state.country = action.payload;
    },
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
    setAllowedCountries(state, action: PayloadAction<string[]>) {
      state.allowedCountries = action.payload;
    },
    resetCountry(state) {
      state.country = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      resetStateForTesting,
      (_state, action) => action.payload.location,
    );
  },
});

export default locationSlice;

const setCountryByCountryCode =
  (countryCode: string) => (dispatch: AppDispatch) => {
    const foundCountry = getCountryObjectByCode(countryCode);
    if (!foundCountry) {
      return;
    }
    const countryLanguageDefault = formatLanguageCode(
      foundCountry.defaultLanguage,
      foundCountry.code,
    );
    dispatch(locationSlice.actions.setCountry(foundCountry));
    dispatch(locationSlice.actions.setLanguage(countryLanguageDefault));
  };

export const locationActions = {
  ...locationSlice.actions,
  setCountryByCountryCode,
};

const selectCountry = createDeepEqualSelector(
  [(state) => state.location.country],
  (country: Country | null) => country,
);

export const locationSelectors = {
  country: selectCountry,
  language: (state: RootState) => state.location.language,
  allowedCountries: (state: RootState) => state.location.allowedCountries,
};
