import { useAppDispatch } from "../../app/store";
import {
  locationActions,
  locationSelectors,
} from "../../common/store/slice/locationSlice";
import { formatLanguageCode, parseLanguageCode } from "../../i18n/utils";
import {
  LanguageOptionType,
  languageOptionsForCountry,
} from "@pslifestyle/common/src/models/languages";
import { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";

export const LanguageSelection = (): ReactElement | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const country = useSelector(locationSelectors.country);
  const language = useSelector(locationSelectors.language);

  const handleLanguageChange = useCallback(
    (selectedLanguage: LanguageOptionType) => {
      if (!country || !selectedLanguage) {
        return;
      }
      const language = formatLanguageCode(selectedLanguage.value, country.code);
      dispatch(locationActions.setLanguage(language));
    },
    [country, dispatch],
  );
  const languageOptions = country ? languageOptionsForCountry(country) : [];
  const chosenLanguage = language
    ? languageOptions.find((option) => {
        const { languageCode } = parseLanguageCode(language);
        return option.value === languageCode;
      })
    : null;

  return (
    <>
      <label className="title-md" htmlFor="languageSelector">
        {t("langChoice", { ns: "frontpage" })}
      </label>
      <Select
        id="languageSelector"
        value={chosenLanguage}
        options={languageOptions}
        isSearchable={false}
        onChange={(evt) => handleLanguageChange(evt as LanguageOptionType)}
      />
    </>
  );
};
