import { Icon } from "../Icon";
import { Theme, themeClassNamesMap } from "./theme";

type Props = {
  url: string;
  text?: string;
  theme?: Theme;
  className?: string;
};
export const ExternalLinkButton = ({ url, text, theme, className }: Props) => (
  <a
    href={url}
    className={`rounded-full flex button-md items-center justify-between ${themeClassNamesMap[theme ?? "primary"]} px-4 py-2 gap-3 ${className ?? ""}`}
  >
    {text ?? url}
    <Icon className="h-[16px] w-[16px]" type="ArrowRight" />
  </a>
);
