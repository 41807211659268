import LogoSvg from "../../assets/images/logo.svg";
import { FullWidthContainer } from "../../common/components/layout/Container";
import Heading from "../../common/components/ui/Heading";
import Paragraph from "../../common/components/ui/Paragraph";
import { ExternalLinkButton } from "../../common/components/ui/buttons/ExternalLinkButton";
import { useTranslation, Trans } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

const partnerLogos = [
  { filename: "sitra", title: "SITRA" },
  { filename: "cscp", title: "CSCP" },
  { filename: "solita", title: "Solita" },
  { filename: "hot_or_cool", title: "Hot or Cool" },
  { filename: "iclei", title: "ICLEI Europe" },
  { filename: "euro_health_net", title: "EuroHealthNet" },
  { filename: "lets_do_it_foundation", title: "Let's Do It Foundation" },
  { filename: "rohetiiger", title: "Green Tiger Foundation" },
  { filename: "athena", title: "Athena - RIC" },
  { filename: "expizo", title: "EKPIZO" },
  { filename: "sustainable_development_foundation", title: "SUSDEF" },
  { filename: "green_apes", title: "greenApes" },
  { filename: "deco", title: "DECO" },
  { filename: "circular_change", title: "Circular Change" },
  { filename: "city_of_ljubljana", title: "MOL" },
  { filename: "zeytince", title: "ZEYDD" },
] as const;

export const OrganizationInfo = () => {
  const { t } = useTranslation(["frontpage"]);

  return (
    <div className="bg-basic-background py-8">
      <FullWidthContainer className="divide-y divide-grey-20">
        <div className="bg-neutral-white px-6 py-8 md:px-10 md:py-11 rounded-lg items-center bg-windmillPattern bg-no-repeat bg-right-top bg-contain mb-8">
          <Heading
            level={2}
            type="headline-md-b"
            className="mb-3 text-neutral-100 md:mr-6"
          >
            {t("feedback.footer.title", { ns: "common" })}
          </Heading>
          <Paragraph type="body-md">
            <Trans
              i18nKey="feedback.footer.description"
              ns="common"
              components={{
                Link: (
                  <Link
                    to="mailto:info@pslifestyle.eu"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="body-md-b"
                  />
                ),
              }}
            />
          </Paragraph>
        </div>
        <div className="py-8 flex flex-col items-start gap-8 lg:gap-[8em] justify-between lg:flex-row lg:items-center">
          <div className="flex flex-col items-start gap-4">
            <img className="h-12" src={LogoSvg} alt="PS Lifestyle" />

            <ExternalLinkButton
              url="http://pslifestyle.eu/"
              text={t("projectWebsite")}
              theme="secondary"
            />
          </div>
          <div className="flex flex-col flex-1 items-start gap-8 justify-between md:flex-row md:items-center">
            <img
              src="/images/flags/EU-flag.svg"
              height="107px"
              width="170px"
              alt="Flag of European Union"
            />
            <Paragraph type="body-md" className="md:ml-5 md:mt-0 mt-5">
              {t("fundingInfo", { ns: "common" })}
            </Paragraph>
          </div>
        </div>
        <div className="py-8">
          <Heading level={1} type="title-lg" className="text-grey-60">
            {t("partnersHeading")}
          </Heading>
          <div className="flex flex-wrap rounded-sm gap-2">
            {partnerLogos.map(({ filename, title }) => (
              <div
                key={filename}
                className="bg-neutral-white flex justify-center items-center h-[80px] w-[80px] md:h-[92px] md:w-[117px]"
              >
                <img
                  alt={title}
                  title={title}
                  src={`/images/partnerLogos/${filename}.png`}
                  className="grayscale"
                />
              </div>
            ))}
          </div>
        </div>
        <NavLink className="pt-8 text-right body-md" to="/privacynotice">
          {t("cookieAndPrivacyPolicy", { ns: "common" })}
        </NavLink>
      </FullWidthContainer>
    </div>
  );
};
