import { FullWidthContainer } from "../../common/components/layout/Container";
import Heading from "../../common/components/ui/Heading";
import Paragraph from "../../common/components/ui/Paragraph";
import { GoToQuestionnaireButton } from "../questionnaire/GoToQuestionnaireButton";
import { AbstractLogo } from "./AbstractLogo";
import { useTranslation } from "react-i18next";

type Props = {
  sectionTanslationKey: string;
};

const LandingPageArticleSection = ({ sectionTanslationKey }: Props) => {
  const { t } = useTranslation(["frontpage"]);
  return (
    <section className="flex flex-start gap-3 child:md:basis-1/2 pb-6">
      <div className="my-auto space-y-2">
        <div className="flex items-center">
          <Heading
            level={2}
            type="headline-md-b"
            className="text-green-dark grow"
          >
            {t(`${sectionTanslationKey}.title`)}
          </Heading>
        </div>

        {t(`${sectionTanslationKey}.text`)
          .split("\n")
          .filter((item) => item)
          .map((segment) => (
            <Paragraph type="body-lg" key={segment} className="first:mt-0 mt-4">
              {segment}
            </Paragraph>
          ))}
      </div>
      <div className="hidden md:flex justify-center items-center max-w-[50%]">
        <AbstractLogo />
      </div>
    </section>
  );
};

export const LandingPageArticle = () => {
  const { t } = useTranslation(["frontpage"]);

  return (
    <FullWidthContainer>
      <article className="flex flex-col-reverse md:flex-col">
        <LandingPageArticleSection
          // change the key to something else
          sectionTanslationKey="heroArticle"
        />

        <div className="flex flex-col md:flex-row md:justify-between bg-yellow-80 px-6 py-9 md:px-12 md:py-16 text-center rounded-lg items-center mb-8">
          <Heading
            level={2}
            type="headline-lg-eb"
            className="mb-4 md:mb-0 text-neutral-100 md:mr-6"
          >
            {t("areYouReady")}
          </Heading>

          <GoToQuestionnaireButton
            trackLocation="home-article"
            theme="blackCTA"
          />
        </div>
      </article>
    </FullWidthContainer>
  );
};
