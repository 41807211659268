import { locationSelectors } from "../../common/store/slice/locationSlice";
import { Hero } from "./Hero";
import { LandingPageArticle } from "./LandingPageArticle";
import { OrganizationInfo } from "./OrganizationInfo";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const LandingPage = () => {
  const country = useSelector(locationSelectors.country);
  const language = useSelector(locationSelectors.language);

  if (!country || !language) {
    return <Navigate to="/selections" />;
  }

  return (
    <div className="bg-neutral-white">
      <Hero />
      <LandingPageArticle />
      <OrganizationInfo />
    </div>
  );
};

export default LandingPage;
